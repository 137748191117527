/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../../stylesheets/ds-stylesheet.css"
import Header from "./header"
import Nav from "./nav"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="site" className="px-8">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="container mx-auto flex flex-col sm:flex-row  py-4">
        <Nav />
        <main className="ds-main w-full">{children}</main>
      </div>
      <footer className="my-8">
        <div className="container mx-auto border-t border-solid border-gray-200 text-right pt-8">
          <a href="#site" className="text-gray-800 hover:underline focus:underline active:underline">Retour en haut de page</a>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
