import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

const NavMenu = () => {
  const data = useStaticQuery(graphql`
    query getMenuCategory {
      allMdx(sort: {fields: fields___priority}) {
        group(field: fields___category) {
          edges {
            node {
              id
              fields {
                slug
                priority
                category
                categoryname
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ul className="m-0">
      {data.allMdx.group.map( currentEdge => (
      <li>
        {currentEdge.edges[0] && (
        <span className="text-gray-500 text-sm uppercase">{currentEdge.edges[0].node.fields.categoryname}</span>
        )}
        <ul className="mb-8">
        {currentEdge.edges.map( currentNode => (
          <li>
            <Link to={currentNode.node.fields.slug} className="text-gray-800 hover:underline focus:underline active:underline">
              {currentNode.node.frontmatter.title}
            </Link>
          </li>
          ))}
        </ul>
      </li>
      ))}
    </ul>
  )
}

export default NavMenu
