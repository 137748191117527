import PropTypes from "prop-types"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import React from "react"
import Brand from "../../images/brand.svg"

const Header = ({ siteTitle }) => {
  return (
    <header className="ds-nav p-4 bg-white bg-fixed">
      <div className="container mx-auto">
        <Helmet>
        </Helmet>
        <h1 className="font-bold text-lg text-gray-800">
          <Link to="/"><img src={Brand} alt={siteTitle} style={{maxWidth: '140px'}} /></Link>
        </h1>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
