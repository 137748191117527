import React from "react"
import NavLink from "./navlink"

const Nav = () => {
    return (
        <nav className="mt-4 w-full sm:w-4/12 md:w-3/12">
            <NavLink />
        </nav>
    )
}

export default Nav